.tile {
    width: 50px;
    height: 45px;
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}
.box-container {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.box {
    width: 150px;
    display: flex;
    flex-flow: row wrap;
}
.reset-container {
    margin-bottom: 10px;
}